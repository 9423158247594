<!--
  - This file is part of the Web3 Library developed by mFactory GmbH.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { evaRefresh } from '@quasar/extras/eva-icons'
import { lamportsToSol, longPriceFormatter } from '~/utils'

const directStakeStore = useDirectStakeStore()
const stakes = computed(() => directStakeStore.directStakes)
const loading = computed(() => directStakeStore.loading)
const totalDirectStake = computed(() => stakes.value.reduce((acc, s) => acc += +s.poolTokenAmount, 0))
const formatPrice = (v: number) => longPriceFormatter.format(lamportsToSol(v))
</script>

<template>
  <div class="wallet-balance__staking__title row">
    <div class="col-sm-4 col-xs text-left">
      {{ $t('other.myDirectStake') }}
    </div>

    <q-btn class="wallet-balance__staking__refresh-btn q-ml-auto" unelevated color="primary" @click="directStakeStore.getStakes">
      <q-icon size="28px" :name="evaRefresh" color="secondary" />
    </q-btn>

    <div class="col-xs col-sm-5">
      <span v-show="!loading">{{ formatPrice(totalDirectStake) }} JSOL</span>
    </div>
  </div>
</template>
