<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts">
import { evaCloseOutline } from '@quasar/extras/eva-icons'
import CopyToClipboard from '~/components/CopyToClipboard.vue'
import type { StakeAccount } from '~/store'
import { formatAmount, formatMoney, lamportsToSol, shortenAddress } from '~/utils'

export default defineComponent({
  components: { CopyToClipboard },
  props: {
    loading: Boolean,
    stakeAccount: {
      type: Object as () => StakeAccount,
      required: true,
    },
    status: String,
  },
  emits: ['deposit', 'deactivate', 'withdraw'],
  setup(props, { emit }) {
    const address = computed(() => props.stakeAccount.pubkey.toBase58())
    const lamports = computed(() => props.stakeAccount?.account?.lamports ?? 0)

    const voteId = computed(() => props.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter)
    const validatorsStore = useValidatorsStore()
    const validators = computed(() => validatorsStore.allValidators)
    const validator = computed(() => validators.value.find(v => v.voteId === voteId.value))

    const amountSol = computed(() => lamportsToSol(lamports.value))
    const coinRateStore = useCoinRateStore()
    return {
      address,
      lamports,
      voteId,
      validator,
      shortAddress: computed(() => shortenAddress(props.stakeAccount.pubkey.toBase58())),
      amount: computed(() => formatAmount(amountSol.value)),
      amountUsd: computed(() => formatMoney(coinRateStore.solana?.price * amountSol.value)),
      state: computed(() =>
        props.stakeAccount.account.data?.parsed?.type !== 'delegated'
          ? 'not delegated'
          : props.status,
      ),
      deposit(directTarget?: boolean) {
        emit('deposit', {
          stakeAccount: props.stakeAccount,
          directTarget: directTarget ? voteId.value : undefined,
        })
      },
      deactivate() {
        emit('deactivate', address.value)
      },
      withdraw() {
        emit('withdraw', address.value, lamports.value)
      },
      evaCloseOutline,
    }
  },
})
</script>

<template>
  <div class="stake-account-wrapper">
    <div class="stake-account">
      <div class="stake-account__info">
        <span class="stake-account__info__address q-mr-xs">{{ shortAddress }}</span>
        <q-tooltip anchor="bottom middle" class="text-body2 break-words">
          {{ address }}
        </q-tooltip>
        <copy-to-clipboard :text="address" />
      </div>
      <div class="stake-account__actions q-mr-sm">
        <template v-if="state === 'active' || state === 'activating'">
          <q-btn
            dense
            color="gray-3"
            unelevated
            @click="deactivate"
          >
            <q-icon size="18px" :name="evaCloseOutline" color="gray-4" />
            <q-tooltip anchor="bottom middle" class="text-body2">
              {{ $t('sidebar.deactivate') }}
            </q-tooltip>
          </q-btn>
          <q-btn
            v-if="state === 'active' && validator && validator.inJpool"
            class="q-ml-sm"
            dense
            color="secondary"
            unelevated
            text-color="black"
          >
            <!-- <q-icon size="28px" :name="evaCloseOutline" color="black" /> -->
            <i-app-deposit-jpool width="16px" />
            <q-tooltip anchor="bottom middle" class="text-body2">
              {{ $t('sidebar.deposit') }}
            </q-tooltip>
            <q-menu>
              <q-list>
                <q-item v-close-popup clickable @click="deposit(false)">
                  <q-item-section>{{ $t('validators.validator.stakeItem.smartStake') }}</q-item-section>
                </q-item>
                <q-item v-close-popup clickable @click="deposit(true)">
                  <q-item-section>{{ $t('validators.validator.stakeItem.directStake') }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </template>
        <template v-else>
          <q-btn
            class="q-ml-auto"
            dense
            :disabled="state === 'deactivating'"
            color="primary"
            unelevated
            @click="withdraw"
          >
            <!-- <q-icon size="28px" :name="evaCloseOutline" color="black" /> -->
            <i-app-withdraw />
            <q-tooltip anchor="bottom middle" class="text-body2">
              {{ state === 'deactivating' ? 'DEACTIVATING' : $t('sidebar.withdraw') }}
            </q-tooltip>
          </q-btn>
        </template>
      </div>

      <div class="stake-account__balance q-pr-xs">
        <div class="stake-account__balance__sol">
          {{ amount }} SOL
        </div>
        <div class="stake-account__balance__usd">
          ${{ amountUsd }}
        </div>
      </div>
    </div>

    <q-inner-loading :showing="loading">
      <q-spinner color="primary" />
    </q-inner-loading>
  </div>
</template>
