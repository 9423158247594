<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { matKeyboardArrowDown } from '@quasar/extras/material-icons'
import jSvt from '~/assets/img/j-logo-green.svg'
import jBacc from '~/assets/img/j-logo-magenta.svg'

defineProps({
  noMoreItem: Boolean,
})

const emit = defineEmits(['closeMenu'])

const { t } = useTranslation()

const VALIDATORS_PAGES_NAMES = [
  '/validators/[vote]/',
  '/validators/[vote]/report',
  '/validators/[vote]/stake-report',
]

export type Route = {
  name: string
  badgeNew?: boolean
  isLink?: boolean
  route: string
  // for submenu
  newSection?: boolean
  icon?: string
}

type NavItem = {
  name: string
  hideName?: boolean
  class?: string
  icon?: string
  isLink?: boolean
  isDisabled?: boolean
  route?: string
  routes?: Route[]
}

const routes = computed<NavItem[]>(() => {
  return [
    {
      route: '/',
      name: t('navTabs.liquidStaking'),
      isLink: false,
    },

    {
      route: '/jstaking',
      name: 'JStaking',
    },
    {
      route: '/high-yield',
      name: t('navTabs.highYield'),
      badgeNew: true,
      isLink: false,
    },
    {
      route: '/flex-staking',
      name: t('navTabs.flexStaking'),
    },
    {
      name: t('navTabs.services'),
      routes: [
        {
          route: '/validators',
          name: t('navTabs.validators'),
        },
        {
          route: '/stake-concentration',
          name: t('navTabs.stakeConc'),
          // example
          isLink: false,
        },
        {
          route: 'https://svt.one',
          name: t('navTabs.svt'),
          isLink: true,
          newSection: true,
          icon: jSvt,
        },
        {
          route: 'https://insights.jpool.one',
          name: t('navTabs.bacc'),
          isLink: true,
          isDisabled: true,
          icon: jBacc,
        },
      ],
    },
    {
      name: 'more',
      class: 'nav-more',
      hideName: true,
      routes: [
        {
          route: '/defi',
          name: t('navTabs.deFi'),
        },
        {
          route: '/holders-club/rewards',
          name: t('stake.stakeMembershipDialog.title'),
        },
      ],
    },
  ]
})

const flatRoutes = routes.value.reduce(
  (acc: Route[], cur: NavItem): Route[] => {
    const pages: Route[] = []
    if (cur.routes) {
      pages.push(...cur.routes)
    } else {
      pages.push(cur as Route)
    }
    return [
      ...acc,
      ...pages,
    ]
  },
  [] as Route[],
)

const route = useRoute()

function ifMenuIsActive(routes?: Route[]) {
  if (VALIDATORS_PAGES_NAMES.find(r => route.name === r) && routes?.find(r => r.route === '/validators')) {
    return true
  }
  return routes?.find(r => r.route === route.path)
}

const openedMenu = ref('')

function closeMenu() {
  emit('closeMenu')
}
</script>

<template>
  <div class="navigation-tabs">
    <template v-for="navItem in (noMoreItem ? flatRoutes : routes)" :key="navItem.name">
      <div
        v-if="(navItem as NavItem).routes"
        class="navigation-tabs__item"
        :class="{ 'router-link-active': ifMenuIsActive((navItem as NavItem).routes), [`${navItem.class}`]: navItem.class }"
      >
        <span>
          <span v-if="!navItem.hideName">
            {{ navItem.name }}
            <q-icon
              :name="matKeyboardArrowDown"
              class="navigation-tabs__menu-arrow q-mb-xs"
              :class="{ 'navigation-tabs__menu-arrow--active': openedMenu === navItem.name }"
              size="18px"
            />
          </span>
          <span v-if="navItem.name === 'more'">
            <i-app-grid-plus />
          </span>
        </span>
        <q-menu
          class="navigation-tabs__menu"
          transition-show="jump-down"
          transition-hide="jump-up"
          @before-show="openedMenu = navItem.name"
          @before-hide="openedMenu = ''"
        >
          <q-list class="navigation-tabs__menu__list">
            <template
              v-for="page in (navItem as NavItem).routes"
              :key="page.name"
            >
              <div v-if="page.newSection" class="navigation-tabs__menu__new-section" />
              <q-item
                v-close-popup
                class="column"
                clickable
                dense
              >
                <navigation-link
                  v-close-popup
                  :page="page"
                  class="navigation-tabs__menu__item q-px-md q-py-sm col-12"
                />
              </q-item>
            </template>
          </q-list>
        </q-menu>
      </div>
      <navigation-link v-else :page="(navItem as Route)" @close-menu="closeMenu" />
    </template>
  </div>
</template>
