<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script setup lang="ts">
import { matAccountBalanceWallet, matClose } from '@quasar/extras/material-icons'
import { RoiCalculator } from '~/features/roi-calculator'

const visible = ref(false)

function toggleSidebar() {
  visible.value = !visible.value
  Analytics.track({ event: 'Open/Close sidebar', action: 'sidebarHandler', label: 'Open/Close sidebar' })
}

function handleClick(e: any) {
  const isOutsideClick = e?.srcElement?.id === 'app-sidebar-wrapper'
  if (isOutsideClick) {
    toggleSidebar()
  }
}
</script>

<template>
  <q-btn
    v-bind="$attrs"
    dense
    unelevated
    class="q-pa-sm"
    color="secondary"
    @click="toggleSidebar"
  >
    <q-icon size="38px" :name="matAccountBalanceWallet" color="black" />
  </q-btn>
  <q-icon
    :name="matClose"
    class="app-sidebar__close"
    :class="{ 'app-sidebar__close--active': visible }"
    @click="visible = false"
  />
  <div
    id="app-sidebar-wrapper"
    class="app-sidebar-wrapper"
    :class="{ 'app-sidebar-wrapper--active': visible }"
    @click="handleClick"
  >
    <div class="app-sidebar">
      <div class="row justify-between">
        <cluster-selector
          class="app-sidebar__cluster-selector"
          :class="{
            'q-mr-md': $q.screen.gt.xs,
            'q-mb-md': $q.screen.lt.sm,
          }"
        />
        <connect-wallet
          class="app-sidebar__wallet-btn"
          :class="{
            'q-ml-md': $q.screen.gt.xs,
          }"
          only-disconnect
        />
      </div>

      <wallet-address class="q-my-lg" />

      <wallet-balance connect-wallet-class="app-sidebar__wallet-balance-connect q-mt-lg full-width" />

      <!-- <referral-program class="q-mt-lg" /> -->

      <solana-price-card class="q-mr-auto q-mt-lg q-mb-sm" />

      <!-- <pool-info-card class="q-mt-lg q-mb-lg" /> -->

      <roi-calculator class="q-mt-auto" />
    </div>
  </div>
</template>
